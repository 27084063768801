import { template as template_2f06d118d61f451cbd9c04a2e80e1a3c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_2f06d118d61f451cbd9c04a2e80e1a3c(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
