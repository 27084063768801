import { template as template_f229bb5ce7a440c4a400f9b778f3ee90 } from "@ember/template-compiler";
const FKLabel = template_f229bb5ce7a440c4a400f9b778f3ee90(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
